<template>
  <div>
    <div
      class="card"
      style="background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 10px;
              overflow-y:auto;"
    >
      <div
        class="pl-3 pr-3 pt-2"
      >
        <div class="">
          <span class="saj-title">
            {{ $t("Search Filter") }}
          </span>
        </div>
        <div class="row align-items-end mb-1">
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Subsidiary") }}:</label>
            <v-select
              v-model="selectedSub"
              class="saj-text select-size-sm"
              style="background: white"
              :placeholder="$t('Choose Subsidiary')"
              :options="subList"
              :reduce="sub => sub.value"
              label="text"
              :disabled="roles.selectedRole === 6"
              :clearable="roles.isAdmin"
              @input="getDepartment(selectedSub), selectedSub === null ? selectedDepartment = null : ''"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 pt-1"
          >
            <label class="saj-text">{{ $t("Department") }}:</label>
            <v-select
              v-model="selectedDepartment"
              class="saj-text select-size-sm"
              style="background: white"
              :placeholder="$t('Choose Department')"
              :options="departmentList"
              :reduce="dept => dept.value"
              label="text"
              :disabled="selectedSub === null"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Employee Number") }}:</label>
            <b-form-input
              v-model="selectedEmployeeNum"
              :placeholder="$t('Search Employee Number')"
              size="sm"
              class="saj-text"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Employee Name") }}:</label>
            <b-form-input
              v-model="selectedEmployeeName"
              :placeholder="$t('Search Employee Name')"
              size="sm"
              class="saj-text"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 pt-1"
          >
            <label class="saj-text">{{ $t("Category") }}:</label>
            <b-form-select
              id="icons-search"
              v-model="selectedCategory"
              :options="categoryList"
              size="sm"
              class="saj-text"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 pt-1"
          >
            <label class="saj-text">{{ $t("Year") }}:</label>
            <Datepicker
              id="input-id"
              v-model="selectedYear"
              :value="customFormatter"
              :format="customFormatter"
              minimum-view="year"
              name="datepicker"
              input-class="year-custom"
              class="show-only-year d-flex"
            />
          </b-col>
          <div class="d-flex mt-1">
            <b-col>
              <b-button
                md="4"
                variant="primary"
                class="d-flex justify-content-center"
                style=""
                @click="show=true, getTraining()"
              >

                <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
              </b-button>
            </b-col>
            <b-col>
              <b-button
                md="4"
                variant="primary"
                class="saj-title d-flex justify-content-center"
                block
                style=""
                @click="clearButton()"
              >
                <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
              </b-button>
            </b-col>
          </div>
        </div>
        <div
          class=""
        >
          <b-overlay
            :show="show"
            rounded="sm"
          >
            <template #overlay>
              <div class="d-flex flex-column align-items-center justify-content-center">
                <b-spinner
                  variant="primary"
                  label="Spinning"
                />
                <p>{{ $t('Fetching Data') }}... </p>
              </div>
            </template>
            <div class="row pl-1 pr-1">
              <b-table
                class="styleTable mt-1"
                style=""
                :items="trainingList"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                label-size="lg"
                bordered
                show-empty
                responsive
              >
                <template #head()="data">
                  <span
                    class="saj-text d-flex justify-content-center"
                  >{{ $t(data.label) }}</span>
                </template>
                <template #cell(index)="data">
                  <div
                    class="saj-text d-flex justify-content-center"
                  >
                    {{ currentPage === 1 ? data.index + 1 : (data.index + 1)+((currentPage-1) *10) }}
                  </div>
                </template>
                <template #empty="">
                  <h4 style="text-align: center; font-style: italic">
                    {{ $t('There are no records to show') }}
                  </h4>
                </template>
                <template #cell(employee_number)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.employee_number }}
                  </div>
                </template>
                <template #cell(name)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.name }}
                  </div>
                </template>
                <template #cell(department)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.department }}
                  </div>
                </template>
                <template #cell(program_title)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.program_title }}
                  </div>
                </template>
                <template #cell(start_date)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ DateChanged(data.item.start_date) }}
                  </div>
                </template>
                <template #cell(end_date)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ DateChanged(data.item.end_date) }}
                  </div>
                </template>
                <template #cell(no_of_hours)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.no_of_hours }}
                  </div>
                </template>
                <template #cell(self_score)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.self_score }}
                  </div>
                </template>
                <template #cell(manager_score)="data">
                  <div
                    class="saj-text"
                    style=""
                  >
                    {{ data.item.manager_score }}
                  </div>
                </template>
              </b-table>
            </div>
            <b-row v-if="trainingList.length > 0">
              <b-col>
                <span
                  class="saj-text"
                  align="start"
                >
                  {{ $t("Showing") }} {{ rows > perPage*currentPage ? perPage*currentPage : rows }} {{ $t("from") }} {{ rows }} {{ $t("entries") }}
                </span>
              </b-col>
              <b-col>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  pills
                  aria-controls="employee-table"
                  class="saj-text mb-1 justify-content-end"
                />
              </b-col>
            </b-row>
          </b-overlay>

          <div
            v-if="trainingList.length > 0"
            class=" d-flex justify-content-center"
          >
            <b-button
              variant="primary"
              class="ml-1 saj-button mb-1"
              :style="roles.isHOD || roles.isHR || roles.isAdmin ? `` : `display: none;`"
              :disabled="exporting"
              @click="exportTraining()"
            >
              <div
                v-if="exporting"
                class="d-flex"
              >
                <b-spinner
                  small
                  type="grow"
                  class="mr-1"
                />
                <span
                  class="saj-button d-md-none d-lg-block"
                > {{ $t("Exporting") }} </span>
              </div>
              <div v-else>
                <span
                  class="saj-button d-md-none d-lg-block"
                > {{ $t("Export Training Record") }} </span>
              </div>

            </b-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  // BFormRadio,
  // BModal,
  BFormInput,
  BFormSelect,
  BRow,
  BTable,
  BCol,
  BButton,
  BOverlay,
  // BButtonGroup,
  BPagination,
  BSpinner,

} from "bootstrap-vue"
import SAJToast from '@/component/saj-toastification.vue'
import vSelect from 'vue-select'
import FileSaver from 'file-saver'
import Datepicker from 'vuejs-datepicker'
import moment from "moment"

import { mapGetters } from 'vuex'

export default {
  components: {
    // BModal,
    // BFormRadio,
    BFormInput,
    BFormSelect,
    BRow,
    BTable,
    BCol,
    BButton,
    BOverlay,
    // BButtonGroup,
    BPagination,
    BSpinner,
    vSelect,
    Datepicker,
  },

  data(){
    return {
      subList: [],
      selectedSub: null,
      departmentList: [],
      selectedDepartment: null,
      empIDList: [{ value: null, text: `${this.$t('Choose Employee Id')}`, disabled: true }],
      selectedEmployeeNum: null,
      selectedEmployeeName: null,
      // filter end
      transferID: null,
      trainingList: [],
      subsidiaryList: [],
      subsidiary_id: null,
      tabIndex: 1,
      show: true,
      currentPage: 1,
      perPage: 10,
      rows: null,
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'employee_number', label: 'Employee Number', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'name', label: 'Name', thClass: 'text-center',
        },
        {
          key: 'department', label: 'Department', thClass: 'text-center',
        },
        {
          key: 'program_title', label: 'Program Title', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'start_date', label: 'Start Date', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'end_date', label: 'End Date', thClass: 'text-center',
        },
        {
          key: 'no_of_hours', label: 'No. of Hours', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'self_score', label: 'Self Score', tdClass: 'text-center',
        },
        {
          key: 'manager_score', label: 'Manager Score', tdClass: 'text-center',
        },
      ],
      allbusinessUnit: null,
      alldepartment: null,
      exporting: false,
      categoryList: [
        { text: `${this.$i18n.t('Choose Category')}`, value: null, disabled: true },
        { text: `${this.$i18n.t('Executive')}`, value: 'Executive' },
        { text: `${this.$i18n.t('Non Executive')}`, value: 'Non Executive' },
      ],
      selectedYear: new Date(),
      selectedCategory: null,
    }
  },

  computed: {
    ...mapGetters(['roles']),
  },
  watch: {
    // $route: {
    //   handler(){
    //     this.getSub_Id()
    //   },

    // },
    // eslint-disable-next-line func-names
    '$i18n.locale': function (){
      this.categoryList = this.categoryList.map(x => {
        if (x.value === null){
          return {
            ...x,
            text: this.$t('Choose Category'),
          }
        }
        return x
      })
    },
  },

  mounted(){
    this.getSubsidiary()

    if (this.roles.isHR) {
      this.getSub_Id()
    } else {
      this.getTraining()
    }
  },

  methods: {
    DateChanged(date) {
      const newDay = moment(date, "").format("D-MM-yyyy")
      return newDay
    },
    customFormatter(date) {
      this.scoreYearSelected = moment(date).format('YYYY')
      return this.scoreYearSelected
    },
    clearButton(){
      this.selectedYear = new Date()
      this.selectedCategory = null
      this.selectedDepartment = null
      this.selectedEmployeeNum = null
      this.selectedEmployeeName = null
      if (this.roles.selectedRole === 1){
        this.selectedSub = null
      }
      // console.log('role', this.roles.selectedRole)
      this.show = true
      this.currentPage = 1

      this.getTraining()
    },
    getSub_Id() {
      this.$axios.get(`${this.$baseUrl}/users/current_user`).then(response => {
        this.selectedSub = response.data.data.employee.business_unit
        if (this.roles.selectedRole === 6){
          this.getDepartment(this.selectedSub)
        }

        if (this.roles.isHR){
          this.getTraining()
        }

        // console.log("siniii sub id user", this.subsidiary_id)
      })
    },
    getSubsidiary(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(response => {
        this.allbusinessUnit = response.data.data.subsidiaries
        // this.allbusinessUnit.forEach(b => {
        //   this.businessUnitList.push({
        //     text: b.subsidiary_name,
        //     value: b.id,

        //   })
        // })

        this.subList = this.allbusinessUnit.map(b => ({
          text: b.subsidiary_name,
          value: b.id,
        }))
      })
    },
    getDepartment(dept){
      if (dept !== null){
        this.$axios.get(`${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${dept}`).then(response => {
          const result = response.data
          // console.log('res', result)
          if (result.success){
            this.alldepartment = response.data.data

            this.departmentList = this.alldepartment.map(d => ({
              text: d.name,
              value: d.id,
            }))

            // if (this.selectedDepartment !== null){
            this.selectedDepartment = null
            // }
          } else {
            this.departmentList = []
            this.selectedDepartment = null
          }
        })
      }
    },
    getTraining() {
      const params = new URLSearchParams()

      if (this.selectedSub !== null){
        params.append('subsidiary_id', this.selectedSub)
      }

      if (this.selectedDepartment !== null){
        params.append('department_id', this.selectedDepartment)
      }

      if (this.selectedEmployeeNum !== null){
        params.append('employee_number', this.selectedEmployeeNum)
      }

      if (this.selectedEmployeeName !== null){
        params.append('employee_name', this.selectedEmployeeName)
      }

      if (this.selectedCategory !== null){
        params.append('category', this.selectedCategory)
      }

      if (this.selectedYear !== null){
        params.append('year', moment(this.selectedYear).year())
      }

      this.$axios.get(`${this.$baseUrl}/training_record/list?status=active`, { params }).then(response => {
        if (response.data.data.length !== 0){
          this.trainingList = response.data.data
          // console.log(this.trainingList)
          // console.log("heree", response.data.data.subsidiaries)
          // this.trainingList.forEach(list => {
          //   this.subsidiaryList.push(list.id)
          // })
        } else {
          this.trainingList = []
        }
        this.show = false

        this.rows = this.trainingList.length
      }).catch(() => {
        this.show = false
        this.trainingList = []
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('No record retrieved')}.`,
              icon: "InfoIcon",
              variant: "success",
              titleColor: "#000",
              iconBg: '#53b7db',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      })
    },
    exportTraining(){
      this.exporting = true
      const data = new FormData()

      // console.log("sini role", this.roles.selectedRole)

      if (this.selectedSub !== null){
        data.append('subsidiary_id', this.selectedSub)
      }

      if (this.selectedDepartment !== null){
        data.append('department_id', this.selectedDepartment)
      }

      if (this.selectedEmployeeNum !== null){
        data.append('employee_number', this.selectedEmployeeNum)
      }

      if (this.selectedEmployeeName !== null){
        data.append('employee_name', this.selectedEmployeeName)
      }

      if (this.selectedCategory !== null){
        data.append('category', this.selectedCategory)
      }

      if (this.selectedYear !== null){
        data.append('year', moment(this.selectedYear).year())
      }

      data.append('status', 'active')

      // data.forEach(x => {
      //   console.log(x)
      // })

      this.exporting = false

      this.$axios.post(`https://api.ranhill-pms.com/api/training_record/export`, data, {
        responseType: 'blob',
      }).then(response => {
        console.log('file dw', response)
        if (response.status === 200){
          this.exporting = false
          FileSaver.saveAs(response.data, "Training_Record.xlsx")
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Your Excel file will be downloaded soon')}!`,
                icon: "CheckCircleIcon",
                variant: "info",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }
      })
        .catch(() => {
          // console.log(error)
        })
    },
  },
}
</script>
<style scoped>
.show-only-year .vdp-datepicker {
    text-align: center !important;
}

.show-only-year .vdp-datepicker__calendar {
    /* bottom: 2px; */
    background: white;
    font-size: 14px;
    padding: 3px;
    border-radius: 6px;
    border: 1px solid #ccc;
}

.show-only-year .vdp-datepicker__calendar .cell.selected {
    background: #ff0000;
    border-radius: 6px;
}

.show-only-year .vdp-datepicker__calendar .cell:hover {
    border: 1px solid #ff0000 !important;
    border-radius: 6px;
}

.year-custom {
  background: #fff;
  border-radius: 6px;
  padding-left: 12px;
  border: none;
  color: grey;
  border: 1px solid #d8d6de;
}
.Margin {
  margin : 0.5rem;
}
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
}

:hover::-webkit-scrollbar-thumb {

    /* background-color: #a0a0a5 !important; */
    background-color: rgb(255, 0, 0)
}

::-webkit-scrollbar-thumb:hover {
    border: 2px solid #bbb9b9 !important;
}
</style>
